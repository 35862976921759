export default {
    fields: {
        reply_id: {type: 'primary'},
        content: {
            title: '内容',
            type: 'bbs_editor', config: {
                height: 290,
                url:'/bbs/upload/image',
                placeholder:'请输入内容',
                menus: [
                    'bold',
                    'fontSize',
                    'foreColor',
                    'backColor',
                    'link',
                    'image',
                ]
            }
        },
        review_status:{type: 'dict_select', dict_key: 'review_status',title:'审核情况',width:100},
        create_time:{title:'创建时间',type:'date',format:'Y-m-d H:i:s',width:180},
    },
    rules: {
        content: [
            {required: true, message: '请输入内容', trigger: 'blur'},
        ],
    },
    group: {
        show: {
            fields: ['reply_id', 'content'],
            show: true,
            title: '修改问答'
        },
        manage_show:{
            fields: ['content', 'review_status','create_time'],
        },
        manage_search:{
            fields: ['review_status'],
        },

        editReply: {
            fields: ['reply_id', 'content'],
            show: true,
            title: '修改问答'
        },
    }
}
