export default {
    fields: {
        answer_id: {type: 'primary'},
        username: {title:'创建人'},
        title: {title: '标题'},
        content: {
            title: '内容', type: 'bbs_editor', config: {
                height: 290,
                url:'/bbs/upload/image',
                placeholder:'请输入内容',
                menus: [
                    'bold',
                    'fontSize',
                    'foreColor',
                    'backColor',
                    'link',
                    'image',
                ]
            }
        },
        type_id: {title: '问题分类', type: 'dict_select', dict_key: 'answer_type'},
        best_reply_id: {title: '最优回复'},
        status: {title: '状态',type:'dict_key',dict_key:'is_enabled',width:100},
        review_status:{type: 'dict_select', dict_key: 'review_status',title:'审核情况',width:100},
        create_time:{title:'创建时间',type:'date',format:'Y-m-d H:i:s',width:180},
    },
    rules: {
        title: [
            {required: true, message: '请指定问题标题', trigger: 'blur'},
        ],
        type_id: [
            {required: true, message: '请指定问题分类', trigger: 'blur'},
        ],
    },
    group: {
        manage_show:{
            fields: ['title', 'status', 'type_id','review_status', 'create_time'],
        },
        manage_comment_show:{
            fields: ['content','review_status', 'create_time'],
        },
        user_show:{
            fields: ['title', 'status', 'review_status', 'create_time'],
        },
        verify: {
            fields: ['title', 'create_time'],
        },
        editQq: {
            fields: ['app_qq_join_key'],
            show: true,
            title: '修改QQ加群Key'
        },
        editElseConfig: {
            fields: ['else_config'],
            show: true,
            title: '广告配置JSON'
        },
        edit: {
            fields: ['id', 'name', 'ad_id', 'times', 'enable', 'priority', 'ad_substitute'],
            show: true,
            title: '修改广告配置'
        },
        editOld: {
            fields: ['id', 'name', 'times', 'ad_id', 'ios_id', 'android_id', 'enable', 'priority', 'ad_substitute'],
            show: true,
            title: '修改广告配置'
        },
        add: {
            fields: ['title', 'type_id', 'content'],
            show: true,
            title: '添加问答'
        },
        editAnswer: {
            fields: ['answer_id','title', 'content'],
            show: true,
            title: '修改问答'
        },
        search: {
            fields: ['review_status'],
        },
        manage_comment_search:{
            fields: ['review_status'],
        },
        manage_search: {
            fields: ['title','review_status','type_id','status'],
        }
    }
}
