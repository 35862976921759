export default {
    fields: {
        id: {type: 'primary'},
        username: {title: '用户名'},
        type: {type: 'dict_key',dict_key:'bbs_piao_type', title: '发票种类'},
        no: {title: '纳税人识别号'},
        header:{title:'发票抬头'},
        content: {title: '发票内容'},
        bank: {title: '开户行'},
        account: {title: '开户行账号'},
        tel: {title: '电话'},
        address: {title: '注册地址'},
        create_time: {title: '创建时间', type: 'date', format: 'Y-m-d'},
    },
    rules: {
        header: [
            {required: true, message: '发票抬头不可以为空', trigger: 'blur'},
        ],
        // content: [
        //     {required: true, message: '发票内容不可以为空', trigger: 'blur'},
        // ],
    },
    group: {
        show: {
            fields: ['type', 'no', 'header', 'content','bank','account','tel','address', 'create_time'],
        },
        search: {
            fields: ['type'],
        },
        edit_pu: {
            fields: ['id', 'header'],
            title: '修改普票抬头',
            data: {},
            show: true,
        },
        edit_zhuan: {
            fields: ['id','header','no','bank','account','tel','address'],
            title: '修改专票抬头',
            data: {},
            show: true,
        },
        add_zhuan: {
            fields:[ {name:'type',type:'hidden'},'header','no','bank','account','tel','address'],
            title: '新增专票抬头',
            data: {},
            show: true,
        },
        add_pu: {
            fields: [{name:'type',type:'hidden'},'header'],
            title: '新增普票抬头',
            data: {},
            show: true,
        },
    }
}
