export default {
    fields: {
        invoice_id: {type:'primary'},
        order_id: {title: '订单号',type:'hidden'},
        invoice_type: {title: '种类',type:'dict_key',dict_key:'bbs_piao_type'},
        no: {title: '纳税人识别号'},
        header: {title: '发票抬头'},
        content: {title: '发票内容',},
        bank: {title: '开户行'},
        account: {title: '开户行账号'},
        tel: {title: '电话'},
        price: {title: '发票金额'},
        address: {title: '注册地址'},
        tip: {title:'备注信息',type:'textarea'},
        mailbox: {title:'邮箱',placeholder:'如果提交了邮箱，那么发票也会同步到邮箱'},
        create_staff_name: {title:'创建人员'},
        status: {title:'状态',type:'dict_tag',dict_key:'bbs_invoice_status'},
        from_title_id:{title:'发票抬头',type:'dict_key',dict_key:'pro_invoice_header'},
        create_time: {title: '申请时间',type:'daterange',sortable1:'custom',valueFormat:'timestamp',format:'Y-m-d'},
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ],
        from_title_id:[
            {required: true, message: '请选择发票抬头', trigger: 'blur'},
        ],
    },
    group: {
        show:{
            fields:['invoice_type','price','header','no','content','status','create_time'],
        },
        add:{
            fields:['order_id','price','no','header','content','bank','account','tel','address'],
            title:'添加发票信息',
            show:true,
        },
        //添加发票信息
        add_invoice:{
            fields:['order_id',{name:'price',show_type:'info'},'from_title_id','mailbox','tip'],
            title:'申请发票',
            show:true,
        },
        edit:{
            fields:['invoice_id','price','no','header','content','bank','account','tel','address'],
            title:'修改发票信息',
            show:true,
        },
        search: {
            fields: ['invoice_type'],
        },
    }
}
